import {Bypaper,deviceinsert,deleteitem,deleteDevice,insertdevicexx,Geinfo,Update,getpartbytype} from '../../api/Machine'


export const state = {
    // aaa:66
}

export const actions = {
    bypaper(_,data) {
        return Bypaper(data)
    },
    insert(_,data) {
        return deviceinsert(data)
    },
    // 删除型号
    delete(_,data) {
        return deleteitem(data)
    },
    // 删除设备
    deletedevice(_,data) {
        return deleteDevice(data)
    },
    // 新增设备
    insertdevice(_,data) {
        return insertdevicexx(data)
    },
    // 分页获取设备信息
    getinfo(_,data) {
        return Geinfo(data)
    },
    // 更新型号信息
    update(_,data) {
        return Update(data)
    },
    // 根据类型获取配件
    getpart(_,data) {
       return getpartbytype(data) 
    }



}
export const mutations = {

}