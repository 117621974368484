import {getList,Insert,PDF,Delete,Update} from '../../api/smart'


export const state = {
    // aaa:66
}

export const actions = {
   
    getlist(_,data) {
        return getList(data)
    },
    insert(_,data) {
        return Insert(data)
    },
    pdf(_,data) {
        return PDF(data)
    },
    delete(_,data) {
        return Delete(data)
    },
    update(_,data) {
        return Update(data)
    }




}
export const mutations = {

}