import http from '../utils/http'
import api from '.'

export function Getlist(data) {
    return http.get(api.User.bypaper, data)
}
export function Insert(data) {
    return http.post(api.User.insert,data)
}
export function Delete(data) {
    return http.post(api.User.delete,data)
}
export function Update(data) {
    return http.post(api.User.update,data)
}
export function Gtqiyebyname(data) {
    return http.get(api.User.getqiyebyname,data)
}
