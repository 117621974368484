import http from '../utils/http'
import api from '.'

export function Bypaper(data) {
    return http.get(api.Machine.bypaper, data)
}
export function deviceinsert(data) {
    return http.post(api.Machine.insert, data)
}
export function deleteitem(data) {
    return http.post(api.Machine.delete + '?' + 'id=' + data.id)
}
export function deleteDevice(data) {

    return http.post(api.Machine.deletedevice + '?' + 'id'+ '=' +data.id + '&' + 'user_id' + '=' + data.user_id)
}

export function insertdevicexx(data) {
    let id = data.user_id
    delete data.user_id
    return http.post(api.Machine.insertdevice+ '?' + 'user_id' + '=' + id,data)
}
export function Geinfo(data) {
    return http.get(api.Machine.devicebypaper, data)
}
export function Update(data) {
    return http.post(api.Machine.update, data)
}
export function getpartbytype(data) {
    return http.get(api.Machine.getpart, data)
}