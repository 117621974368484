import {Bypaper,Insert,Photo,Getorder,updateordeR,get,ZUOfei,PDF,Delete} from '../../api/Contract'


export const state = {
    // aaa:66
}

export const actions = {
    // 分页获取合同信息
    bypaper(_,data) {
        return Bypaper(data)
    },
    // 新增合同
    insert(_,data) {
        return Insert(data)
    },
    // 插入合同照片
    photo(_,data) {
        // console.log(data,xxx,_,'第一步插入图片')
        return Photo(data)
    },
    // 插入合同pdf
    pdf(_,data) {
        // console.log(data,xxx,_,'第一步插入图片')
        return PDF(data)
    },

    // 删除合同
    delete(_,data) {
        return Delete(data)
    },
    // 修改合同
    update(_,data) {
        return Update(data)
    },
    // 获取订单信息
    getorder(_,data) {
        return Getorder(data)
    },
    // 修改订单信息
    updateorder(_,data) {
        return updateordeR(data)
    },
    // 获取合同列表
    getcontract(_,data){
        return get(data)
    },
    // 作废合同
    zuofei(_,data) {
        return ZUOfei(data)
    }




}
export const mutations = {

}