import {Getlist,Insert,bankshow,Update} from '../../api/stock'


export const state = {
    // aaa:66
}

export const actions = {
    getlist(_,data) {
        return Getlist(data)
    },
    insert(_,data) {
        return Insert(data)
    },
    //获取库存信息 
    getbankshow(_,data) {
        return bankshow(data)
    },
    // 修改库存
    update(_,data) {
        return Update(data)
    }
   


}
export const mutations = {

}