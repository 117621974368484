import http from '../utils/http'
import api from '.'

export function Insert(data) {
    return http.post(api.customer.insert, data)
}
export function Getlist(data) {
    return http.get(api.customer.getlist,data)
}
export function Update(data) {
    return http.post(api.customer.update,data)
}
export function qrcode(data) {
    return http.post(api.customer.create + '?' +'custom_id=' + data.custom_id + '&' + 'way=' + data.way + '?' +'custom_id=' + data.custom_id)
    
}
export function getdevice(data) {
    return http.get(api.customer.getdevice,data)
}
export function Add(data) {
    return http.post(api.customer.add,data)
}

export function deviceupdate(data) {
    return http.post(api.customer.deviceofupdate,data)
}
export function Getcustomermachine(data) {
    return http.get(api.customer.device,data)
}
export function getclear(data) {
    return http.get(api.customer.clear,data)
}
export function Delete(data) {
    return http.post(api.customer.delete + '?' + 'id=' + data.id)
}

