import {Login} from '../../api/Login'


export const state = {
    // aaa:66
}

export const actions = {
    login(_,data) {
        return Login(data)
    }

}
export const mutations = {

}