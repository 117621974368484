import { Insert,Getlist,Update,qrcode,getdevice,Add,deviceupdate,Getcustomermachine,getclear,Delete } from '@/api/customer'


export const state = {
    // aaa:66
}

export const actions = {
    // 新增客户
    insert(_,data) {
        return Insert(data)
    },
    getlist(_,data) {
        return Getlist(data)
    },
    update(_,data){
        return Update(data)
    },
    // 生成用户二维码
    created(_,data) {
        return qrcode(data)
    },
    // 获取客户设备信息(分页)
    getcustomerdevice(_,data) {
        return getdevice(data)
    },
    // 客户新增设备
    add(_,data) {
        return Add(data)
    },
    // 客户减少设备
    delete(_,data) {
        return Delete(data)
    },
    // 修改客户设备信息
    updatedevice(_,data) {
        return deviceupdate(data)
    },
    // 根据客户id  获取设备信息
    getcustomermachine(_,data) {
        return Getcustomermachine(data)
    },
    clear(_,data) {
        return getclear(data)
    }



}
export const mutations = {

}