import {Getlist,Insert,Delete,Update,Gtqiyebyname} from '../../api/user'


export const state = {
    // aaa:66
}

export const actions = {
    getlist(_,data) {
        return Getlist(data)
    },
    // 添加用户
    insert(_,data) {
        return Insert(data)
    },
    // 删除用户
    delete(_,data) {
        return Delete(data)
    },
    // 编辑用户
    update(_,data){
        return Update(data)
    },
    // 根据用户别名获取企业
    getqiyebyname(_,data) {
        return Gtqiyebyname(data)
    }


}
export const mutations = {

}