import {Insert,Getlist,Update,Get} from '../../api/head'


export const state = {
    // aaa:66
}

export const actions = {
    insert(_,data) {
        return Insert(data)
    },
    getlist(_,data) {
        return Getlist(data)
    },
    update(_,data) {
        return Update(data)
    },
    get(_,data) {
        return Get(data)
    }




}
export const mutations = {

}