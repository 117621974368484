import http from '../utils/http'
import api from '.'

export function Insert(data) {
    return http.post(api.Contract.insert, data)
}
export function Bypaper(data) {
    return http.get(api.Contract.getlist, data)
}
export function Photo(data) {
    // console.log(data,'插入图片的接口',aaa)
    // let id = data.contract_id
    // delete data.contract_id
    return http.post(api.Contract.photo + '?' + 'contract_id=' + data.contract_id, data.file)
}
export function PDF(data) {
    // console.log(data,'插入图片的接口',aaa)
    // let id = data.contract_id
    // delete data.contract_id
    return http.post(api.Contract.pdf + '?' + 'contract_id=' + data.contract_id, data.file)
}

export function Getorder(data) {
    return http.get(api.Contract.getorder + '?' + 'contract_id=' + data.contract_id)
}
export function updateordeR(data) {
    return http.post(api.Contract.updateorder,data)
}
export function get(data) {
    return http.get(api.Contract.get,data)
}
export function ZUOfei(data) {
    return http.get(api.Contract.zuofei,data)
}
export function Delete(data) {
    return http.post(api.Contract.delete + '?id=' + data.id)
}

