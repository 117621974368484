import http from '../utils/http'
import api from '.'

export function Insert(data) {
    return http.post(api.task.insert,data)
}
export function Getlist(data) {
    return http.get(api.task.bypaper,data)
}

export function distributetask(data) {
    return http.get(api.task.distribute,data)
}
export function Update(data) {
    return http.post(api.task.update,data)
}
export function gettask(data) {
    return http.get(api.task.byid,data)
}
export function removetask(data) {
    return http.post(api.task.remove + '?' + 'task_id=' + data.routerid,data.arr)
}

export function tasltask(data) {
    return http.post(api.task.tasl + '?' + 'task_id=' + data.task_id + '&record=',data.file)
}
export function status(data) {
    return http.get(api.task.editstatus,data)
}
export function Makepdf(data) {
    return http.get(api.task.write,data)
}
export function Getbaoxiu(data) {
    return http.get(api.task.baoxiu,data)
}

export function DEletebaoxiu(data) {
    return http.get(api.task.deletebaoxiu,data)
}
 

