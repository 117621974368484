import http from '../utils/http'
import api from '.'

export function getList(data) {
    return http.get(api.smart.getlist, data)
}
export function Insert(data) {
    return http.post(api.smart.insert,data)
}
export function PDF(data) {
    return http.post(api.smart.pdf + '?' + 'id=' + data.id, data.file)
}
export function Delete(data) {
    return http.post(api.smart.delete + '?' + 'id=' + data.id)
}
export function Update(data) {
    return http.post(api.smart.update,data)
}


