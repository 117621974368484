import axios from 'axios'
// import vue from '../main'
import router from '@/router'

const service = axios.create({
    // baseURL: '/',
    validateStatus:null,
    timeout:20000
})
// export default axios.create({
//     baseURL:'https://zeiot.vip/',
//     // baseURL: process.env.VUE_APP_BASE_URL,
//     validateStatus:null,
//     timeout:20000
// })


axios.default.withCredentials = true
// service.defaults.headers.common["Authorization"] = 'bearer ' + window.localStorage.getItem('token');
service.defaults.headers.common["Access-Control-Allow-Origin"] = '*';


//请求拦截器
service.interceptors.request.use(
    (config) => {
        // config.headers['X-CSRF-TOKEN'] = 'token'
        if(config.url.split('/')[0] == 'daili') {
            delete config.headers.token
            // service.defaults.headers.common["token"] = ''
        }
        config.headers["Authorization"]= 'bearer ' + window.localStorage.getItem('token');
        // console.log('请求拦截里面的',config)
        return config
    }
)
service.interceptors.response.use(
    (config) => {
        config.headers['X-CSRF-TOKEN'] = 'token'
        // console.log('相应拦截里面的',config)
        if(config.status == 401) {

            router.push({name:'login'})
            localStorage.removeItem('token')
            // this.$router.push({name: 'login'})
        }

        return config
    }
)



export default service
