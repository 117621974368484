import http from '../utils/http'
import api from '.'

export function Realdata(data) {
    return http.get(api.get.realdata, data)
}


export function set(data) {
    return http.post(api.get.setrealdata + '?' + 'num=' + data.num)
}
export function getdaynum(data) {
    return http.get(api.get.daynum,data)
}