import http from '../utils/http'
import api from '.'

export function Getlist(data) {
    return http.get(api.stock.bypaper, data)
}
export function Insert(data) {
    return http.post(api.stock.insert,data)
}
export function bankshow(data) {
    return http.get(api.stock.getbankshow,data)
}
export function Update(data) {
    return http.post(api.stock.update,data)
}

