import http from '../utils/http'
import api from '.'

export function Insert(data) {
    return http.post(api.head.insert, data)
}
export function Getlist(data) {
    return http.get(api.head.getlist, data)
}
export function Update(data) {
    return http.post(api.head.update, data)
}
export function Get(data) {
    return http.get(api.head.get, data)
}

