import request from "./request";

const http = {
  /**
   * 发送GET请求
   * @param url 请求地址
   * @param params 请求参数
   */
  get(url, params) {
    const config = {
      method: "get",
      url: url,
      // token:'11'
    };
    if (params) {
      config.params = params;
    }
    return request(config);
  },
  post(url, data) {
    const config = {
      method: "post",
      url: url,
      // token:'11'
    };
    if (data) config.data = data;
    // if (data) config.data = {...data, _token:localStorage.getItem('token')};
    return request(config);
  },
  put(url, data) {
    const config = {
      method: "put",
      url: url,
    };
    if (data) config.data = data;
    return request(config);
  },
  delete(url, data) {
    const config = {
      method: "delete",
      url: url,
    };
    if (data) config.data = data;
    return request(config);
  },
};

export default http;
