<template>
  <div id="app">
    <router-view></router-view>

    <!-- <router-view  v-if="!!token"></router-view> -->
    <!-- <login v-else></login> -->
  </div>
</template>
<script>
import login from '@/views/Login/login.vue'
export default({
  components: {
      login
  },
  mounted() {

  },
  data() {
    return {
      token: localStorage.getItem('token')
    }
  }
})
</script>

<style lang="scss">
html,body {
  margin: 0;
  padding: 0;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  height: 100%;
}
</style>
