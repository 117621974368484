<template>

   <div class="page-login">
        <div class="inner">

          <el-form ref="login" label-width="100px" :model="data" :rules="rules">
                <el-form-item label="用户名" prop="username">
                  <el-input placeholder="请输入用户名" v-model="data.username"></el-input>
                </el-form-item>

                <el-form-item label='密 码' prop="password">
                  <label slot="label">密&nbsp;&nbsp;&nbsp;码</label>
                  <el-input  v-model="data.password" placeholder="请输入密码" type="password"></el-input>
                </el-form-item>     
          </el-form>
              <el-button type="success" @click="submitData">登录</el-button>
        </div>
   </div>
</template>

<script>
// import {username, password} from '../utils/validate'
export default {
  data(){
    //定义校验规则
    this.rules = {
      username: [{required:true,message:'请输入内容'}],
      password: [{required:true,message:'请输入内容'}]
    }
    return {
      num: Math.ceil(Math.random() * 3),
      data: {

        username:'',
        password:''
      }
    }
  },
  methods: {
    // submitData() {
    //   // console.log(this.$refs.login)
    //   this.$refs.login.validate(valid => {
    //     if(valid) {
    //       // console.log(123,this.data)
    //       //按照接口文档发送请求
    //       this.$store.dispatch('login/login', {
    //         user_phone: this.data.username,
    //         password: this.data.password
    //       }).then(res => {
    //         console.log(res,'看一看结果')
    //         if(res.status !== 200) {
    //             this.$message.info(res.data)
    //         }else {
    //             localStorage.setItem('token',res.data.token)
    //             localStorage.setItem('user_id',res.data.user_id)
    //             localStorage.setItem('user_role',res.data.user_role)
    //             // 在这里写如果成功的情况
    //             // this
    //             this.$router.push({name:'lthome'})
    //         }
    //       })



    //     }
    //     // console.log(valid)

    //   })
      
    // }
    submitData() {
      window.open('www.baidu.com')
    }
  }
}
</script>
<style lang="scss">
.page-login {
  position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #eee;
    .inner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      border-radius: 20px;
      background: rgba(255,255,255,0.8);
      width:400px;
      padding: 20px;

      text-align: center;


    }
    .el-button {
      // display: block;
      width: 100%;
    }
    .el-form-item__label {
      font-weight: normal;
      // font-size: 20px;
      color: black;
    }

}

</style>