import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/LTHome.vue'




Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'lthome',
    component: HomeView,
    children:[
      {
        path: '/machine',
        name: 'machine',
        component: () => import(/* webpackChunkName: "about" */ '../views/machine/index.vue')
      },
      {
        path: '/modelnumber',
        name: 'modelnumber',
        component: () => import(/* webpackChunkName: "about" */ '../views/model/index.vue')
      },
      {
        path: '/stock',
        name: 'stockname',
        component: () => import(/* webpackChunkName: "about" */ '../views/stock/index.vue')
      },
      {
        path: '/stockin',
        name: 'stockinname',
        component: () => import(/* webpackChunkName: "about" */ '../views/stock/stockin/index.vue')
      },
      {
        path: '/stockout',
        name: 'stockoutname',
        component: () => import(/* webpackChunkName: "about" */ '../views/stock/stockout/index.vue')
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "about" */ '../views/contact/index.vue')
      },
      {
        path: '/contact/detail/:id',
        name: 'contactdetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/contactdetail/index.vue')
      },
      {
        path: '/makeproduct',
        name: 'makeproduct',
        component: () => import(/* webpackChunkName: "about" */ '../views/make/index.vue')
      },
      {
        path: '/daynum',
        name: 'daynum',
        component: () => import(/* webpackChunkName: "about" */ '../views/day/index.vue')
      },
      {
        path: '/task',
        name: 'task',
        component: () => import(/* webpackChunkName: "about" */ '../views/task/index.vue')
      },
      {
        path: '/principal',
        name: 'principal',
        component: () => import(/* webpackChunkName: "about" */ '../views/principal/index.vue')
      },
      {
        path: '/customer',
        name: 'customer',
        component: () => import(/* webpackChunkName: "about" */ '../views/customer/index.vue')
      },
      {
        path: '/customermap',
        name: 'customermap',
        component: () => import(/* webpackChunkName: "about" */ '../views/map/index.vue')
      },

      {
        path: '/user',
        name: 'user',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/index.vue')
      },
      {
        path: '/statistics',
        name: 'statistics',
        component: () => import(/* webpackChunkName: "about" */ '../views/statistics/index.vue')
      },
      {
        path: '/zhishiku',
        name: 'zhishiku',
        component: () => import(/* webpackChunkName: "about" */ '../views/zhishiku/index.vue')
      },
      {
        path: '/baoxiu',
        name: 'baoxiu',
        component: () => import(/* webpackChunkName: "about" */ '../views/baoxiu/index.vue')
      }


    ]

  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/login-design.vue'),
  },
]
let originPush =  VueRouter.prototype.push;
VueRouter.prototype.push = function (location, resolve, reject){
  if (resolve && reject) {    //如果传了回调函数，直接使用
      originPush.call(this, location, resolve, reject);
  }else {                     //如果没有传回调函数，手动添加
      originPush.call(this, location, ()=>{}, ()=>{}); 
  }
}

const router = new VueRouter({
  mode: 'hash',
  routes,
  scrollBehavior: () => ({ left: 0, top: 0 }),
})
router.afterEach(() => {
  window.scrollTo(0,0)
})
const whiteList = ['/login']
router.beforeEach((to, form, next) => {
  // to 即将要进入路由的对象
  // from 要离开的路由对象
  // 调用 next() 方法，进入下一个路由
  let token = localStorage.getItem('token')
  // try {
  //   let userInfo = JSON.parse(sessionStorage.userInfo) // 将 sessionStorage 字符串解析为对象
  //   // 防止刷新页面 vuex 中的用户信息清除，将 sessionStorage 中的用户信息再存储到 vuex 中
  //   store.dispatch('setUserInfo', userInfo)
  //   token = userInfo.token
  // } catch (error) {
  //   token = ''
  // }
  // console.log(router.getRoutes())
  console.log(to,form,666,'路由')
  // console.log(window.location)
  // console.log(to.query.redirect.split("/id=")[1])
  if(to?.query?.id) {
    sessionStorage.setItem('qiyeid',to?.query?.id)
  }
  // else {
  //   sessionStorage.setItem('qiyeid','4444')
  // }

  
  if (token) {
    // 如果有 token，登录成功
    if (to.path === '/login') {
      // 访问登录页，就重定向到首页
      next({
        path: '/'
      })
    } else {
      // 访问其他页面，进入下一个路由
      next()
    }
  } else {
    // 没有 token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在不需要重定向的页面直接登录
      next()
    } else {
      // 其他没有访问权限的页面被重定向到登录页
      next(`/login?redirect=${to.path}`)
    }
  }
})

export default router
