import { Realdata,set,getdaynum } from '@/api/product'


export const state = {
    // aaa:66
}

export const actions = {
    realdata(_,data) {
        return Realdata(data)
    },
    // 设置实时数据
    setrealdata(_,data) {
        return set(data)
    },
    // 获取生产管理每日计数
    daynum(_,data) {
       return getdaynum(data)
    }


}
export const mutations = {

}