import {Getlist} from '../../api/part'


export const state = {
    // aaa:66
}

export const actions = {
    getlist(_,data) {
        return Getlist(data)
    }
}
export const mutations = {

}