export default {
    Login: {
        login:'api/jtzn/login'
    },
    Machine: {
        // 分页获取设备型号
        bypaper:'api/jtzn/get/protocol/bypaper',
        // 分页获取设备信息
        devicebypaper: 'api/jtzn/get/device/bypaper',
        // 新增设备型号
        insert:'api/jtzn/insert/protocol',
        // 删除型号
        delete:'api/jtzn/delete/protocol',
        // 修改型号信息
        update:'api/jtzn/update/protocol',
        // 新增设备
        insertdevice:'api/jtzn/insert/device',
        // 删除设备信息.
        deletedevice:'api/jtzn/delete/device',
        // 修改型号信息
        update:'/api/jtzn/update/protocol',
        getpart:'/api/jtzn/get/part/bytype'
    },
    User: {
        // 分页获取用户
        bypaper:'api/jtzn/get/user/bypaper',
        // 新建用户
        insert:'api/jtzn/insert/user',
        // 删除用户
        delete:'api/jtzn/delete/user',
        // 编辑用户
        update:'api/jtzn/update/user',
        getqiyebyname:"/api/jtzn/get/enterprise/by/another"
    },
    Contract:{
        bypaper:'api/jtzn/get/contract/bypaper',
        insert:'api/jtzn/insert/contract',
        photo:'api/jtzn/insert/contract/photo',
        delete:'/api/jtzn/delete/contract',
        update:'api/jtzn/update/contract',
        getlist:'api/jtzn/get/contract/bypaper',
        getorder:'api/jtzn/get/order',
        updateorder:'api/jtzn/update/order',
        get:'api/jtzn/get/contract',
        zuofei:'api/jtzn/update/contract/status',
        pdf:'api/jtzn/insert/contract/pdf'
    },
    stock:{
        bypaper:'api/jtzn/get/record/bypaper',
        insert:'api/jtzn/insert/part/bank',
        // 分页获取库存信息
        getbankshow:'api/jtzn/get/bankshow/bypaper',
        // 修改库存
        update:'api/jtzn/update/bank'
    },
    part: {
        get:'api/jtzn/get/part'
    },
    head: {
        insert:'api/jtzn/insert/head',
        getlist:'api/jtzn/get/head/bypaper',
        update:'api/jtzn/update/head',
        get:'api/jtzn/get/head'
    },
    customer: {
        insert:'api/jtzn/insert/custom',
        getlist:'api/jtzn/get/custom/bypaper',
        update:'api/jtzn/update/custom',
        create:'api/jtzn/create/qrcode',
        getdevice:'api/jtzn/get/custom/device/bypaper',
        add:'api/jtzn/insert/custom/device',
        deviceofupdate:'api/jtzn/update/custom/device',
        device:'/api/jtzn/get/custom/device',
        clear:'api/jtzn/get/clean/remind',
        delete:'api/jtzn/delete/custom/device'
    },
    get: {
        realdata:'api/jtzn/get/realdata',
        setrealdata:'api/jtzn/set/realdata',
        daynum:'api/jtzn/get/count/day/num'
    },
    smart: {
        getlist:'api/jtzn/get/smart',
        insert:'api/jtzn/insert/smart',
        pdf:'api/jtzn/smart/pdf',
        delete:'api/jtzn/delete/smart',
        update:'api/jtzn/update/smart'

    },
    task: {
        insert:'api/jtzn/insert/task',
        bypaper:'api/jtzn/get/task/bypaper',
        // 这个是任务下发
        distribute:'api/jtzn/task/distribute',

        update:'api/jtzn/update/task',
        byid:'api/jtzn/get/task/byid',
        remove:'api/jtzn/task_remove',
        tasl:'api/jtzn/tasl/record',
        editstatus:'/api/jtzn/update/task/status',
        write:'api/jtzn/word/write',
        baoxiu:'api/jtzn/get/fault/bypaper',
        deletebaoxiu:'api/jtzn/delete/fault'

    }


}