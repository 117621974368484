import {Insert,Getlist,distributetask,Update,gettask,removetask,tasltask,status,Makepdf,Getbaoxiu,DEletebaoxiu} from '../../api/task'


export const state = {
    // aaa:66
}

export const actions = {
    insert(_,data) {
        return Insert(data)
    },
    // 分页获取任务信息
    getlist(_,data) {
        return Getlist(data)
    },
    // 任务下发
    distribute(_,data) {
        return distributetask(data)
    },
    // 这个是修改任务信息
    update(_,data) {
        return Update(data)
    },
    // 根据id获取任务详情
    byid(_,data) {
        return gettask(data)
    },
    // 移交任务
    task_remove(_,data) {
        return removetask(data)
    },
    // 上传维护记录图片
    tasl(_,data) {
        return tasltask(data)
    },
    // 单纯修改任务状态
    editstatus(_,data) {
        return status(data)
    },
    makepdf(_,data) {
        return Makepdf(data)
    },
    // 分页获取保修
    getbaoxiu(_,data) {
        return Getbaoxiu(data)
    },
    // 删除保修
    deletebaoxiu(_,data) {
        return DEletebaoxiu(data)
    }








}
export const mutations = {

}